<template>
  <div>
    <div v-if="resource">
      <div class="card mb-4">
        <div class="card-header">
          Listado de Estados
        </div>
        <div class="card-body">
          <div v-for="(estado, index) in resource.data" :key="estado.id">
            <b-row>
              <!-- Atributos -->
              <b-col class="d-flex align-items-center">
                <!-- icon -->
                <i data-feather="flag"></i>

                <div class="ml-4">
                  <div class="small">{{ estado.iso }}</div>
                  <div class="text-xs text-muted">
                    {{ estado.nombre }}
                  </div>
                </div>
              </b-col>

              <!-- Controles -->
              <b-col>
                <b-container class="h-100 d-flex justify-content-end align-items-center">
                  <u-d-controls
                    :id="estado.id"
                    editPath="permissionModule/getPermission"
                    deletePath="permissionModule/deletePermission"
                    :editPermissions="['editar estados']"
                    hideDeleteAction
                    hideEditAction
                    @on-got-item="up"
                    class="my-2 justify-content-end"
                  />
                </b-container>
              </b-col>
            </b-row>
            <hr v-show="index < resource.data.length - 1" />
          </div>
        </div>
      </div>
    </div>

    <loading v-else></loading>
  </div>
</template>

<script>
import UDControls from '@/components/Shared/UDControls'
import { mapActions, mapState } from 'vuex'

const API_MODULE = 'estadoModule'

export default {
  name: 'EstadosList',

  components: {
    UDControls
  },

  created () {
    this.getResource()
  },

  computed: {
    ...mapState(API_MODULE, ['resource'])
  },

  methods: {
    ...mapActions(API_MODULE, ['getResource']),

    up () {
      window.scrollTo(0, 120)
    }
  }
}
</script>

<style lang="scss" scoped></style>
