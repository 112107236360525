<template>
  <div>
    <check-authorization :requiresAuthorizations="['indice municipios']">
      <municipios-list />
    </check-authorization>
  </div>

</template>

<script>
import MunicipiosList from '@/components/Catalogos/Municipios/MunicipiosList'

export default {
  name: 'Municipios',

  components: {
    MunicipiosList
  }
}
</script>

<style lang="scss" scoped></style>
