<template>
  <div>
    <check-authorization :requiresAuthorizations="['indice estados']">
      <estados-list />
    </check-authorization>
  </div>
</template>

<script>
import EstadosList from '@/components/Catalogos/Estados/EstadosList'

export default {
  name: 'Estados',

  components: {
    EstadosList
  }
}
</script>

<style lang="scss" scoped></style>
