<template>
  <base-view title="Países, Estados, Municipios" icon="flag">
    <!-- Account page navigation-->
    <nav class="my-nav nav nav-borders">
      <router-link
        v-for="route in paisesEstadosAndMunicipiosViews"
        :key="route.id"
        :to="{ name: route.name, params: { tabActive: route.name } }"
        :class="getActiveClass(route.path)"
        class="nav-link my-active ml-0"
      >
        <check-authorization :requiresAuthorizations="route.meta.requiresPermissions" hide>
          {{ route.meta.title }}
        </check-authorization>
      </router-link>
    </nav>

    <hr class="mt-0 mb-4" />

    <!-- Main view -->
    <component :is="selectedComponent"></component>
  </base-view>
</template>

<script>
//
// Este componente maneja dinámicamente los 3 conceptos
// Países, Estados y Municipios
// Se toma directamente como base el componente de Usuarios, Roles y Permisos
//
// Este es el componente padre que renderiza un componente
// dinámico según sea el caso de pestaña que se ha de seleccionar.
//
import Paises from '@/components/Catalogos/Paises/Paises/'
import Estados from '@/components/Catalogos/Estados/Estados'
import Municipios from '@/components/Catalogos/Municipios/Municipios'
import { paisesEstadosAndMunicipiosViews } from '@/router/routes'

export default {
  name: 'PaisesEstadosMunicipiosView',

  components: {
    //
    // Componentes dinámicos
    Paises,
    Estados,
    Municipios
  },

  props: {
    tabActive: {
      type: String,
      required: true,
      validator: function (value) {
        return ['paises', 'estados', 'municipios'].indexOf(value) !== -1
      }
    }
  },

  data () {
    return {
      paisesEstadosAndMunicipiosViews,
      selectedComponent: this.tabActive
    }
  },

  watch: {
    $route (route) {
      this.selectedComponent = route.params.tabActive
    }
  },

  methods: {
    getActiveClass (path) {
      return this.$route.path === path ? 'active' : ''
    }
  }
}
</script>
