<template>
  <div>
    <check-authorization :requiresAuthorizations="['indice paises']">
      <paises-list />
    </check-authorization>
  </div>
</template>

<script>
import PaisesList from '@/components/Catalogos/Paises/PaisesList'

export default {
  name: 'Paises',

  components: {
    PaisesList
  }
}
</script>

<style lang="scss" scoped></style>
